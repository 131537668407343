export default {
    keys: {
        /** 当前登录人（含Token） */
        loginUser: 'c1',
        /** 页面参数 */
        pageParam: 'c2:',
        /** 页面缓存 */
        pageCache: 'c3:',
    },
    /**
     * 获取缓存对象
     * @param {string} key 缓存名称
     * @returns 缓存对象
     */
    getObject(key) {
        if (!key) {
            return null
        }
        const str = window.localStorage.getItem(key)
        if (!str) {
            return null
        }
        try {
            const obj = JSON.parse(str)
            return obj
        } catch {
            return null
        }
    },
    /**
     * 保存缓存对象
     * @param {string} key 缓存名称
     * @param {object} obj 缓存对象
     */
    setObject(key, obj) {
        if (key) {
            if (obj) {
                window.localStorage.setItem(key, JSON.stringify(obj))
            } else {
                window.localStorage.removeItem(key)
            }
        }
    },
    /**
     * 获取缓存String
     * @param {string} key 缓存名称
     * @returns 缓存String
     */
    getString(key) {
        if (!key) {
            return null
        }
        return window.localStorage.getItem(key)
    },
    /**
     * 保存缓存String
     * @param {string} key 缓存名称
     * @param {object} str 缓存String
     */
    setString(key, str) {
        if (key) {
            if (str) {
                window.localStorage.setItem(key, str)
            } else {
                window.localStorage.removeItem(key)
            }
        }
    }
}