import axios from "axios"
import router from '../router/index'
import { ElMessage } from 'element-plus'
import cache from '../utils/cache'
const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  },
})
api.interceptors.request.use(request => {
  const user = cache.getObject(cache.keys.loginUser)
  if (user && user.token) {
    request.headers.Token = user.token
  }
  request.data = request.data || {}
  return request
})
api.interceptors.response.use(res => {
  if (res.status != 200 || !res.data) {
    // ElMessage.error('网络连接异常，请稍后再试')
    ElMessage.error(JSON.stringify(res))
    console.error(res)
    throw res
  }
  if (res.data.code != 'S000001') {
    if (res.data && res.data.code == 'P000002') {// 登录失效
      cache.setObject(cache.keys.loginUser, null)
      ElMessage.warning('登录超时，请重新登录')
      router.push('/login')
      throw res
    }
    if (res.data && res.data.code == 'P000003') {// 无权限
      ElMessage.warning('无权限')
      throw res
    }
    if (res.data && res.data.message) {
      ElMessage.error(res.data.message)
      throw res
    }
    // ElMessage.error('网络连接异常，请稍后再试')
    ElMessage.error(JSON.stringify(res))
    console.error(res)
    throw res
  }
  return res.data.data
})

api.baseURL = () => { return '/api' }
api.tokenHeader = () => {
  const result = {}
  const user = cache.getObject(cache.keys.loginUser)
  if (user && user.token) {
    result.Token = user.token
  }
  return result
}
export default api